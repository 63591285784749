import { render } from "./TitleHeader.vue?vue&type=template&id=398a9c38&scoped=true"
import script from "./TitleHeader.vue?vue&type=script&lang=js"
export * from "./TitleHeader.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-398a9c38"
/* hot reload */
if (module.hot) {
  script.__hmrId = "398a9c38"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('398a9c38', script)) {
    api.reload('398a9c38', script)
  }
  
  module.hot.accept("./TitleHeader.vue?vue&type=template&id=398a9c38&scoped=true", () => {
    api.rerender('398a9c38', render)
  })

}

script.__file = "resources/js/components/Elements/TitleHeader.vue"

export default script