import { render } from "./Home.vue?vue&type=template&id=d124beaa&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-d124beaa"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d124beaa"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d124beaa', script)) {
    api.reload('d124beaa', script)
  }
  
  module.hot.accept("./Home.vue?vue&type=template&id=d124beaa&scoped=true", () => {
    api.rerender('d124beaa', render)
  })

}

script.__file = "resources/js/components/Views/Home.vue"

export default script