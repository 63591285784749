import { render } from "./AgendaItem.vue?vue&type=template&id=3fe66c61&scoped=true"
import script from "./AgendaItem.vue?vue&type=script&lang=js"
export * from "./AgendaItem.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-3fe66c61"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3fe66c61"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3fe66c61', script)) {
    api.reload('3fe66c61', script)
  }
  
  module.hot.accept("./AgendaItem.vue?vue&type=template&id=3fe66c61&scoped=true", () => {
    api.rerender('3fe66c61', render)
  })

}

script.__file = "resources/js/components/Partials/AgendaItem.vue"

export default script