export default {
    loginError: false,
    pageTitle: "Home",
    secrets: [],
    sidebarOpened: true,
    user: null,
    userStatus: null,
    viewport: "",
    teams: [
        // Temp data
        // {
        //     _id: "ID_019355",
        //     title: "Test List",
        //     slug: "",
        // },
        // {
        //     _id: "ID_0193",
        //     title: "Decoupled Discussion",
        //     slug: "decoupled-discussion",
        // },
        // {
        //     _id: "ID_01945",
        //     title: "Engineering React",
        //     slug: "engineering-react",
        // },
        // {
        //     _id: "ID_0084",
        //     title: "Engineering Monthly Meeting",
        //     slug: "engineering-monthly-meeting",
        // },
    ],
};
