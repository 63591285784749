import { render } from "./Loading.vue?vue&type=template&id=2515806f&scoped=true"
import script from "./Loading.vue?vue&type=script&lang=js"
export * from "./Loading.vue?vue&type=script&lang=js"

import "./Loading.vue?vue&type=style&index=0&id=2515806f&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-2515806f"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2515806f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2515806f', script)) {
    api.reload('2515806f', script)
  }
  
  module.hot.accept("./Loading.vue?vue&type=template&id=2515806f&scoped=true", () => {
    api.rerender('2515806f', render)
  })

}

script.__file = "resources/js/components/Elements/Loading.vue"

export default script