import { render } from "./MainNavigation.vue?vue&type=template&id=2285eb1e&scoped=true"
import script from "./MainNavigation.vue?vue&type=script&lang=js"
export * from "./MainNavigation.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-2285eb1e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2285eb1e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2285eb1e', script)) {
    api.reload('2285eb1e', script)
  }
  
  module.hot.accept("./MainNavigation.vue?vue&type=template&id=2285eb1e&scoped=true", () => {
    api.rerender('2285eb1e', render)
  })

}

script.__file = "resources/js/components/Header/Navigation/MainNavigation.vue"

export default script