import { render } from "./SidebarItem.vue?vue&type=template&id=60541646&scoped=true"
import script from "./SidebarItem.vue?vue&type=script&lang=js"
export * from "./SidebarItem.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-60541646"
/* hot reload */
if (module.hot) {
  script.__hmrId = "60541646"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('60541646', script)) {
    api.reload('60541646', script)
  }
  
  module.hot.accept("./SidebarItem.vue?vue&type=template&id=60541646&scoped=true", () => {
    api.rerender('60541646', render)
  })

}

script.__file = "resources/js/components/Partials/SidebarItem.vue"

export default script