import { render } from "./Drag-Handle.vue?vue&type=template&id=20ff8434&scoped=true"
import script from "./Drag-Handle.vue?vue&type=script&lang=js"
export * from "./Drag-Handle.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-20ff8434"
/* hot reload */
if (module.hot) {
  script.__hmrId = "20ff8434"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('20ff8434', script)) {
    api.reload('20ff8434', script)
  }
  
  module.hot.accept("./Drag-Handle.vue?vue&type=template&id=20ff8434&scoped=true", () => {
    api.rerender('20ff8434', render)
  })

}

script.__file = "resources/js/components/Elements/Drag-Handle.vue"

export default script