import { render } from "./Sidebar.vue?vue&type=template&id=4f50f02a&scoped=true"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-4f50f02a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f50f02a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f50f02a', script)) {
    api.reload('4f50f02a', script)
  }
  
  module.hot.accept("./Sidebar.vue?vue&type=template&id=4f50f02a&scoped=true", () => {
    api.rerender('4f50f02a', render)
  })

}

script.__file = "resources/js/components/Partials/Sidebar.vue"

export default script