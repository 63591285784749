import { render } from "./Header.vue?vue&type=template&id=770cded8&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-770cded8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "770cded8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('770cded8', script)) {
    api.reload('770cded8', script)
  }
  
  module.hot.accept("./Header.vue?vue&type=template&id=770cded8&scoped=true", () => {
    api.rerender('770cded8', render)
  })

}

script.__file = "resources/js/components/Header/Header.vue"

export default script