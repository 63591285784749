import { render } from "./ProfileNavigation.vue?vue&type=template&id=135abf89&scoped=true"
import script from "./ProfileNavigation.vue?vue&type=script&lang=js"
export * from "./ProfileNavigation.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-135abf89"
/* hot reload */
if (module.hot) {
  script.__hmrId = "135abf89"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('135abf89', script)) {
    api.reload('135abf89', script)
  }
  
  module.hot.accept("./ProfileNavigation.vue?vue&type=template&id=135abf89&scoped=true", () => {
    api.rerender('135abf89', render)
  })

}

script.__file = "resources/js/components/Header/Navigation/ProfileNavigation.vue"

export default script