import { render } from "./Agenda.vue?vue&type=template&id=1a4f0c58&scoped=true"
import script from "./Agenda.vue?vue&type=script&lang=js"
export * from "./Agenda.vue?vue&type=script&lang=js"

import "./Agenda.vue?vue&type=style&index=0&id=1a4f0c58&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1a4f0c58"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1a4f0c58"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1a4f0c58', script)) {
    api.reload('1a4f0c58', script)
  }
  
  module.hot.accept("./Agenda.vue?vue&type=template&id=1a4f0c58&scoped=true", () => {
    api.rerender('1a4f0c58', render)
  })

}

script.__file = "resources/js/components/Views/Agenda.vue"

export default script