import { render } from "./Layout.vue?vue&type=template&id=380565f4&scoped=true"
import script from "./Layout.vue?vue&type=script&lang=js"
export * from "./Layout.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-380565f4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "380565f4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('380565f4', script)) {
    api.reload('380565f4', script)
  }
  
  module.hot.accept("./Layout.vue?vue&type=template&id=380565f4&scoped=true", () => {
    api.rerender('380565f4', render)
  })

}

script.__file = "resources/js/components/Layout/Layout.vue"

export default script